export const environment = {
    production: false,
    Base: 'https://stagingapi.etammn.net',
    pusher: {
        PUSHER_APP_ID: 1503727,
        PUSHER_APP_KEY: 'd806013bf9389025d2fa',
        PUSHER_APP_SECRET: 'e2db4624795f96877cbe',
        PUSHER_APP_CLUSTER: 'eu'
    },
    ClientCred: {
        phone: '',
        password: ''
    },
    AdminCred: {
        email: '',
        password: ''
    },
    MaintenanceCred: {
        email: '',
        password: ''
    },
    FacilityCred: {
        email: '',
        password: ''
    },
    InsuranceCred: {
        email: '',
        password: ''
    }
};
